import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { FormattedDate } from 'react-intl';
import { FormattedNumber } from 'react-intl';
import { Decode } from 'xrpl-tagged-address-codec';
import { QRCode } from 'react-qrcode-logo';
import LedgerTag from '../lib/LedgerTag';
import Money from '../lib/Money';
import AccountTag from '../lib/AccountTag';
import VerifiedBadge from '../lib/VerifiedBadge';
import Callout from '../lib/Callout';
import CardTitleContainer from '../lib/CardTitleContainer';
import {
	getAdvisoryTypeStyle,
	getInactiveAccountStyle,
	getAccountActivationStatus,
	getBlackholeAccountStatus,
	getBlackholeAccountStyle,
} from '../common/Helpers';
import XummKycTag from '../lib/XummKycTag';
import { AdvisoryType } from '../common/Constants';


const AccountInfo = (props) => {
	const { account, addressParam } = props;

	const getAvatar = () => {
		 if (account &&
			account.settings &&
			account.settings.emailHash &&
			account.settings.emailHash.length > 0
			) {
			return `https://www.gravatar.com/avatar/${account.settings.emailHash.toLowerCase()}.png?d=blank&s=45`
		}
		else if (account &&
			account.accountName &&
			account.accountName.twitter
			) {
			// return `${XRPSCAN_API_URL}/avatar/${account.accountName.twitter}`;
			return `https://avatars.io/twitter/${account.accountName.twitter}`;
		}
		else {
			return '';
		}
	};

	// Decode X-Address
	const decodeXAddress = (xa) => {
		if (
			xa.startsWith('X') &&
			xa.length >= 46 &&
			xa.length <= 48
			) {
			try{
				return Decode(xa);
			}
			catch {
				return null;
			}
		}
	};
	const xAddress = decodeXAddress(addressParam);


	// If this Account has an advisory issued against it, show an alert box.
	const getAdvisoryMessage = (type) => {
		let variant = '';
		let message = '';
		switch (type) {
			case AdvisoryType.SPAM:
				variant = 'warning';
				message = 'This account is reported as a spammer or fraudulent address. Please proceed with caution.';
				break;
			case AdvisoryType.HACK:
				variant = 'warning';
				message = 'This account is reported as being involved in a recent hack or scam. Please proceed with caution.';
				break;
			case AdvisoryType.LEA:
				variant = 'danger';
				message = 'This account is reported by a law enforcement agency as scam or fraudulent address. Please proceed with caution.';
				break;
			default:
				break;
		}

		return (
			<Row>
				<Col xs={12} md="12">
					<Callout title="FRAUD ALERT" variant={variant}><span className='ml-2'>{message}</span></Callout>
				</Col>
			</Row>
		);
	}

	// Account activation status
	const accountStatus = getAccountActivationStatus(account);

	// If this Account is inactive, show an alert box.
	const getInactiveAccountMessage = () => {
		const style = getInactiveAccountStyle(accountStatus);
		return (
			<Row>
				<Col xs={12} md={12}>
				{accountStatus === 'new' &&
					<Callout title="INACTIVE ACCOUNT" variant={style}>
						&nbsp; This account is inactive. It can be activated by sending a minimum 10 XRP to this address.
					</Callout>
				}
				{accountStatus === 'deleted' &&
					<Callout title="ACCOUNT DELETED" variant={style}>
						&nbsp; This account has been deleted and is inactive. It can be reactivated by sending a minimum 10 XRP to this address.
					</Callout>
				}
				</Col>
			</Row>
		);
	}

	// Account blackhole status
	const blackholeStatus = getBlackholeAccountStatus(account.settings);

	// If this Account is Blackholed, show an alert box
	const getBlackholeAccountMessage = () => {
		const style = getBlackholeAccountStyle(blackholeStatus);
		return (
			<Row>
				<Col xs={12} md={12}>
					<Callout title="BLACKHOLED ACCOUNT" variant={style}>
						<span className="ml-2">This account is permanently blackholed. It cannot sign any transactions or issue new tokens.</span>
					</Callout>
				</Col>
			</Row>
		)
	}

	// If this Account is AMM account, show an alert box
	const getAMMAccountMessage = () => {
		return (
			<Row>
				<Col xs={12} md={12}>
					<Callout title="AMM ACCOUNT" variant="success">
						<span className="ml-2">This is an automatically created AMM account. It cannot sign any transactions or issue new tokens.</span>
					</Callout>
				</Col>
			</Row>
		)
	}

	// Clean up and sanitize Domain name field
	const getSanitizedDomain = () => {
		if (account.settings && typeof(account.settings.domain) === 'string') {
			const domain = account.settings.domain;
			if (domain.startsWith('http://') || domain.startsWith('https://')) {
				return domain;
			}
			else {
				return `https://${account.settings.domain}`;
			}
		}
		else if (account.accountName && typeof(account.accountName.domain) === 'string') {
			return `https://${account.accountName.domain}`;
		}
	}

	// Calculate Account's total reserve amount and return.
	const getAccountReserve = () => {
		const baseReserve = 10;
		const ownerReserve = (account.ownerCount ? account.ownerCount : 0) * 2;
		return baseReserve + ownerReserve;
	}

	return (
		<Card border={getAdvisoryTypeStyle(account.advisory) || getInactiveAccountStyle(accountStatus) || getBlackholeAccountStyle(blackholeStatus)} className="shadow-sm">
			<Card.Body>

				{account.advisory && account.advisory.type &&
					getAdvisoryMessage(account.advisory.type)
				}

				{(accountStatus === 'new' || accountStatus === 'deleted') &&
					getInactiveAccountMessage()
				}

				{blackholeStatus &&
					getBlackholeAccountMessage()
				}

				{account.AMMID && typeof account.AMMID === 'string' && account.AMMID.length === 64 &&
					getAMMAccountMessage()
				}

				<Card.Title>
					<CardTitleContainer icon="user" title="Account summary" />
				</Card.Title>
				<Row>
					<Col xs={12} md={5}>
						<Table responsive>
							<thead>
								{account.accountName && account.accountName.username &&
								<tr>
									<th className="data-header">User</th>
									<th>
										<AccountTag name={account.accountName} link>{account.account}</AccountTag>
									</th>
								</tr>
								}
								{account.accountName && account.accountName.name &&
								<tr>
									<th className="data-header">Name</th>
									<th>
										<AccountTag name={account.accountName} link>{account.account}</AccountTag>
										{account.accountName.verified &&
											<VerifiedBadge />
										}
									</th>
								</tr>
								}
								{!account.accountName &&
								<tr>
									<th className="data-header">Address</th>
									<th><AccountTag link>{account.account}</AccountTag></th>
								</tr>
								}
							</thead>

							<tbody>
								{account.accountName &&
								<tr>
									<td>Address</td>
									<td><Link to={`/account/${account.account}`}>{account.account}</Link></td>
								</tr>
								}

								{account.previousAffectingTransactionID &&
								<tr>
									<td>Last tx:</td>
									<td className="text-muted">
										<span className="address-tag">
											<Link to={`/tx/${account.previousAffectingTransactionID}`}>
												{account.previousAffectingTransactionID}
											</Link>
										</span>
										<LedgerTag ledger_index={account.previousAffectingTransactionLedgerVersion} />
									</td>
								</tr>
								}

								{ account.parent &&
								<tr>
									<td>Activated by:</td>
									<td className="text-muted"><AccountTag name={account.parentName} link>{account.parent}</AccountTag></td>
								</tr>
								}

								{ account.inception &&
								<tr>
									<td className="data-header">On:</td>
									<td>
										<FormattedDate 
											value={account.inception}
											year='numeric' 
											month='short' 
											day='2-digit' 
											hour='2-digit' 
											minute='2-digit' 
											second='2-digit'
											timeZone='UTC'
											timeZoneName='short'
											/>
									</td>
								</tr>
								}

								{ account.initial_balance &&
								<tr>
									<td>Initial balance:</td>
									<td>
										<span className="hash"><Money value={account.initial_balance} /></span>
										{account.tx_hash &&
										<>
										<span className="ml-2 mr-1">via tx:</span>
										<span className="text-muted address-tag">
											<Link to={`/tx/${account.tx_hash}`}>
												{account.tx_hash}
											</Link>
										</span>
										</>
										}

									</td>
								</tr>
								}

							</tbody>
						</Table>
					</Col>

					<Col xs={12} md={3}>
						<Table responsive>
							<thead>
								<tr>
									<th colSpan="2">Properties</th>
								</tr>
							</thead>
							<tbody>

								{account.sequence &&
								<tr>
									<td>Next seq:</td>
									<td>
										<FormattedNumber value={account.sequence}	/>
									</td>
								</tr>
								}

								{account.sequence && account.ledger_index && account.ledger_index > 55313921 &&
								<tr>
									<td>Faux seq:</td>
									<td>
										<FormattedNumber value={account.sequence - account.ledger_index + 1} />
									</td>
								</tr>
								}

								{(account.settings && account.settings.requireDestinationTag) &&
								<tr>
									<td>Destination tag:</td>
									<td><Badge variant="success">REQUIRED</Badge></td>
								</tr>
								}

								{(account.settings && account.settings.disableMasterKey) &&
								<tr>
									<td>Master key:</td>
									<td><Badge variant="warning">DISABLED</Badge></td>
								</tr>
								}

								{(account.settings && account.settings.globalFreeze) &&
								<tr>
									<td>Global Freeze:</td>
									<td><Badge variant="warning">YES</Badge></td>
								</tr>
								}

								{(account.settings && account.settings.defaultRipple) &&
								<tr>
									<td>Rippling:</td>
									<td><Badge variant="success">ENABLED</Badge></td>
								</tr>
								}

								{(account.settings && account.settings.disallowIncomingXRP) &&
								<tr>
									<td>Incoming XRP:</td>
									<td><Badge variant="warning">DISALLOW</Badge></td>
								</tr>
								}

								{(account.settings && account.settings.passwordSpent) &&
								<tr>
									<td>Password spent:</td>
									<td><Badge variant="warning">YES</Badge></td>
								</tr>
								}

								{(account.settings && account.settings.depositAuth) &&
								<tr>
									<td>Deposit Auth:</td>
									<td><Badge variant="success">ENABLED</Badge></td>
								</tr>
								}

								{(account?.settings?.signers?.threshold >= 0) &&
								<tr>
									<td>Multisig:</td>
									<td><Badge variant="success">ENABLED</Badge></td>
								</tr>
								}

								{account.account &&
									<XummKycTag address={account.account}/>
								}

								{account.MintedNFTokens &&
								<tr>
									<td>NFT Minted:</td>
									<td>{account.MintedNFTokens}</td>
								</tr>
								}

								{account.BurnedNFTokens &&
								<tr>
									<td>NFT Burned:</td>
									<td>{account.BurnedNFTokens}</td>
								</tr>
								}

								{account.NFTokenMinter &&
								<tr>
									<td>NFT Minter:</td>
									<td><AccountTag link minimal>{account.NFTokenMinter}</AccountTag></td>
								</tr>
								}

								{account.index &&
								<tr>
									<td>Account root:</td>
									<td>
										<span className="address-tag"><Link to={`/object/${account.index}`}>{account.index}</Link></span>
									</td>
								</tr>
								}
							</tbody>
						</Table>
					</Col>

					<Col xs={12} md={4}>
						<Table responsive>
							<thead>
								{account.account &&
								<tr className="table-secondary">
									<td colSpan="2" className="text-center">
										<QRCode
											value={ account.account }
											ecLevel={"H"}
											size={109}
											quietZone={0}
											logoImage={getAvatar()}
											logoWidth={36}
											logoHeight={36}
											logoOpacity={1}
											logoPadding={5}
											logoPaddingStyle={'circle'}
											removeQrCodeBehindLogo={true}
											enableCORS={true}
											fgColor={"#004a7c"}
											eyeColor={"#004a7c"}
											qrStyle='dots'
										/>
									</td>
								</tr>
								}
							</thead>
							<tbody>
								{((account.settings && account.settings.domain) || (account.settings && account.accountName && account.accountName.domain)) &&
								<tr>
									<td>Domain:</td>
									<td>
										<span>
											<a href={getSanitizedDomain()} target="_blank" rel="noopener noreferrer nofollow">
												{getSanitizedDomain()}
											</a>
										</span>
									</td>
								</tr>
								}
								{account.accountName && account.accountName.twitter &&
								<tr>
									<td>Twitter:</td>
									<td>
										<span>
											<a href={`https://twitter.com/${account.accountName.twitter}`} target="_blank" rel="noopener noreferrer nofollow">
												@{account.accountName.twitter}
											</a>
										</span>
									</td>
								</tr>
								}
							</tbody>
						</Table>

						{account.xrpBalance &&
						<Tabs fill defaultActiveKey="balance" id="balance-tabs" className="remove-card-header-tabs">
							<Tab eventKey="balance" title="Balance">
								<ListGroup>
									<ListGroup.Item variant="success" className="text-center accountinfo-balance">
										<Money value={Number(account.xrpBalance)} />
									</ListGroup.Item>
								</ListGroup>
							</Tab>
							<Tab eventKey="reserve" title="Reserve">
								<ListGroup>
									<ListGroup.Item variant="secondary" className="text-center accountinfo-balance">
										<Money value={getAccountReserve()} />
									</ListGroup.Item>
								</ListGroup>
							</Tab>
							<Tab eventKey="available" title="Available">
								<ListGroup>
									<ListGroup.Item variant="primary" className="text-center accountinfo-balance">
										<Money value={Number(account.xrpBalance) - getAccountReserve()} />
									</ListGroup.Item>
								</ListGroup>
							</Tab>
						</Tabs>
						}

						{(accountStatus === 'new' || accountStatus === 'deleted') &&
						<Button variant={getInactiveAccountStyle(accountStatus)} size="sm" block disabled>Inactive</Button>
						}

					</Col>
				</Row>

				{xAddress &&
				<Row>
					<Col xs={12} md={12}>
						<Table responsive>
							<thead>
								<tr>
									<th colSpan="2" className="data-header">Tagged address</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>X-Address</td>
									<td>
										<AccountTag link>{addressParam}</AccountTag>
									</td>
								</tr>
								<tr>
									<td>Account</td>
									<td>
										<AccountTag link dt={xAddress.tag||undefined}>{xAddress.account}</AccountTag>
									</td>
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				}
			</Card.Body>
		</Card>
	);
}


AccountInfo.propTypes = {
	account: PropTypes.object.isRequired,
	addressParam: PropTypes.string,
	error: PropTypes.object,	
}

export default AccountInfo;
