import React from 'react';
import Alert from 'react-bootstrap/Alert';

const IntegrationGuide = () => {
  return (
    <div className="d-none d-md-block">
      <Alert variant={'info'}>
        Do you work for a Bank or Cryptocurrency Exchange? Its easy to integrate XRPSCAN in your app's workflow. Read our <Alert.Link href="https://docs.xrpscan.com/help/integration-guide" target="_blank">Integration guide &rarr;</Alert.Link>
      </Alert>
    </div>
  );
}

export default IntegrationGuide;