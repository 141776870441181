import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';

const MoonpayButton = () => {
  const popover = (
    <Popover id="popover-sponsor">
      <Popover.Title>
        MoonPay: buy XRP with a credit card.&nbsp;
        <span className="small"><Badge variant="secondary">SPONSORED</Badge></span>
      </Popover.Title>
      <Popover.Content>
        Choose from 50+ digital assets and checkout with your preferred payment
        method. Buy, sell and swap crypto. Trusted by 20M+ users worldwide.
      </Popover.Content>
    </Popover>
  );

  return (
    <span>
        <OverlayTrigger
          key={'bottom'}
          placement={'bottom'}
          overlay={popover}
        >
          <Button
            variant="info"
            size="sm"
            href="https://api.xrpscan.com/api/v1/campaign/moonpaybutton"
            target="_blank"
            rel="noopener nofollow"
            className="dropdown-toggle"
          >Buy XRP with Credit Card</Button>
        </OverlayTrigger>
    </span>
  )
}

export default MoonpayButton;