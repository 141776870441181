import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Money from '../lib/Money';
import AccountTag from '../lib/AccountTag';
import EmptyStateNoRecord from '../lib/EmptyStateNoRecord';
import Loading from '../common/Loading';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import AdvancedTable from '../lib/AdvancedTable';
import { FormattedNumber } from 'react-intl';
import XBadge from '../lib/XBadge';
import XIcon from '../lib/XIcon';

/*
* Add custom properties to represent Money etc.
*/
const addProperties = (_balances, total_coins) => {
	const populatedBalances = _balances.map((b, index) => {
		b.percentage = <FormattedNumber value={getPercentOfSupply(b.balance, b.escrow, total_coins)} maximumFractionDigits={3} minimumFractionDigits={3}/>
		b.index = index + 1
		b.balance = b.balance ? <Money value={b.balance} drops min={0} max={0}/> : ""
		if (b.escrow) {
			b.escrow = <XBadge variant="primary"> <XIcon icon="plus"/><Money value={b.escrow} drops min={0} max={0}/> </XBadge>
		}
		if (b.name) {
			if (b.name.username) {
				b.accountName = `~${b.name.username}`
			}
			else if (b.name.desc) {
				b.accountName = `${b.name.name} (${b.name.desc})`
			} else {
				b.accountName = b.name.name
			}
		}
		return b;
	});
	return populatedBalances;
}

const getPercentOfSupply = (balance, escrow, total_coins) => {
	if (balance > 0 && total_coins > 0) {
		const total_balance = (escrow > 0) ? (balance + escrow) : balance;
		return (total_balance / total_coins) * 100;
	} else {
		return 0;
	}
}

const BalanceTable = (props) => {
  const { total_coins } = props;
  const [ balances, setBalances ] = useState([])
	const [ loading, setLoading ] = useState(false);

	const columns = useMemo(
		() => [
			{
				Header: "Top 10,000 XRP balances",
				columns: [
				{
					Header: "#",
					accessor: "index",
				},
				{
					Header: "Account",
					accessor: "account",
					Cell: ({ cell: { value }}) => <AccountTag link>{value}</AccountTag>,
        			className: "text-monospace",
				},
				{
					Header: "Name",
					accessor: "accountName",
				},
				{
					Header: "Balance",
					accessor: "balance",
					className: "text-right",
				},
				{
					Header: "In Escrow",
					accessor: "escrow",
					className: "text-center",
				},
				{
					Header: "%",
					accessor: "percentage",
					Cell: ({ cell: { value }}) => <XBadge variant="success">{value}%</XBadge>,
        			className: "text-center text-monospace",					
				},
				],
			},
			],
      [],
	)

  /*
  * Fetch latest account balances
  */
  useEffect(() => {
		setLoading(true);
		fetch(`${XRPSCAN_API_URL}/balances`)
    .then(handleResponse)
    .then((data) => {
      setBalances(addProperties(data, total_coins));
    })
    .catch(() => {
      setBalances([]);
    })
    .finally(() => {
      setLoading(false);
    });
    return () => {
      setBalances([]);
    }
  }, [total_coins]);

	if (loading) {
		return <Loading/>;
	}

	if (!loading && balances && balances.length === 0) {
		return (
			<EmptyStateNoRecord title="Balances">
				Top account balances are unavailable at the moment.
			</EmptyStateNoRecord>
		)
	} else {
		return <AdvancedTable
			columns={columns}
			data={balances}
			filterProperty='accountName'
			filterPlaceholder='Search account names'
    		defaultPageSizes={[500, 1000, 5000]}
    		initialPageSize={500}
		/>
	}
}

BalanceTable.propTypes = {
  total_coins: PropTypes.number,
}

export default BalanceTable;