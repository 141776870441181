import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { getTxResultStyle, getTxResultLabel } from '../common/Helpers';
import Money from '../lib/Money';
import TxType from '../lib/TxType';
import AccountTag from '../lib/AccountTag';
import PartialPaymentTag from '../lib/PartialPaymentTag';
import XIcon from '../lib/XIcon';
import XBadge from '../lib/XBadge';
import RipplingIcon from './RipplingIcon';
import DeepSearchTag from './DeepSearchTag';


const TxItem = (props) => {
  const { tx, address, activationHash } = props;

  const isTrustlineRemoved = (tx) => {
    return (
      tx &&
      tx.LimitAmount &&
      tx.LimitAmount.value &&
      Number(tx.LimitAmount.value) === 0
    ) ? true : false;
  }

  return  (
    <tr
      className={ getTxResultStyle(tx.meta.TransactionResult) === 'success' ? '' : getTxResultStyle(tx.meta.TransactionResult, 'table-')}
    >
      <td>
        <TxType type={tx.TransactionType} />
      </td>
      <td>
        <span className="text-nowrap">
          <FormattedDate value={tx.date} timeZone="UTC" year='numeric' month='2-digit' day='2-digit'/>&nbsp;
          <span className="text-muted">
            <FormattedTime value={tx.date} timeZone="UTC" hour='2-digit' minute='2-digit' hour12={false}/>
          </span>
        </span>
      </td>
      <td>
        <span className="address-tag"><Link to={`/tx/${tx.hash}`}>{tx.hash}</Link></span>
      </td>
      <td>
        <span className="text-muted text-nowrap">
          <AccountTag name={tx.AccountName} link={!(tx.Account === address)} st={tx.SourceTag} minimal>
            {tx.Account}
          </AccountTag>
          { typeof tx.SourceTag === 'number' && tx.SourceTag >= 0 &&
          <span className="ml-2">
            <DeepSearchTag
              Account={tx.Account}
              SourceTag={tx.SourceTag}
              TransactionType={tx.TransactionType}
            />
          </span>
          }
        </span>
      </td>
      <td className="text-center">
        { (tx.Destination === address && tx.Account !== address) ?
            tx.hash === activationHash ?
              <Badge variant="success">ACTIVATED</Badge>
            :  <Badge variant="success">&nbsp;&nbsp;IN&nbsp;&nbsp;</Badge>
          : ''}
        { tx.Account === address && <Badge variant="warning">OUT</Badge> }
        { tx.Account !== address && tx.Destination !== address && tx.TransactionType !== 'Payment' && <XIcon icon="arrow-right" /> }
        { tx.Account !== address && tx.Destination !== address && tx.TransactionType === 'Payment' && <RipplingIcon /> }
      </td>
      <td>
        <span className="text-muted text-nowrap">
        { tx.Destination ?
            <AccountTag name={tx.DestinationName} link={!(tx.Destination === address)} minimal dt={tx.DestinationTag}>
              {tx.Destination}
            </AccountTag>
          : 'XRPL'
        }
        { typeof tx.DestinationTag === 'number' && tx.DestinationTag >= 0 &&
          <span className="ml-2">
            <DeepSearchTag
              Destination={tx.Destination}
              DestinationTag={tx.DestinationTag}
              TransactionType={tx.TransactionType}
            />
          </span>
        }
        </span>
      </td>
      <td className="text-right text-nowrap">
        { tx.Amount &&
          tx.meta &&
          tx.meta.delivered_amount &&
          tx.Amount.value !== tx.meta.delivered_amount.value &&
          <PartialPaymentTag amount={tx.Amount}/>
        }
        { tx.meta && tx.meta.delivered_amount &&
          <Money
            value={tx.meta.delivered_amount.value}
            currency={tx.meta.delivered_amount.currency}
            issuer={tx.meta.delivered_amount.issuer}
            drops
            min={6}
            max={6}
          />
        }
        {!(tx.meta && tx.meta.delivered_amount) && tx.Amount &&
          <Money
            value={tx.Amount.value}
            currency={tx.Amount.currency}
            issuer={tx.Amount.issuer}
            drops
            min={6}
            max={6}
          />
        }
        {!(tx.meta && tx.meta.delivered_amount) && !tx.Amount && tx.SendMax &&
          <Money
            value={tx.SendMax.value}
            currency={tx.SendMax.currency}
            issuer={tx.SendMax.issuer}
            drops
            min={6}
            max={6}
          />
        }
        { tx.TakerGets &&
          <Money
            value={tx.TakerGets.value}
            currency={tx.TakerGets.currency}
            issuer={tx.TakerGets.issuer}
            drops
            min={6}
            max={6}
          />
        }
        { tx.TransactionType === "TrustSet" && tx.LimitAmount &&
          <>
          <XBadge variant={isTrustlineRemoved(tx) ? 'warning' : 'success'} className="hash">
            TRUST
            <XIcon icon={isTrustlineRemoved(tx) ? 'minus' : 'plus'} className="ml-1"/>
          </XBadge>
          <XBadge variant='light'>
            <Money
              value={tx.LimitAmount.value}
              currency={tx.LimitAmount.currency}
              issuer={tx.LimitAmount.issuer}
              max={2}
            />
          </XBadge>
          </>
        }
        { tx.TransactionType === "TicketCreate" && tx.TicketCount > 0 &&
          <>
            <XIcon className="text-info" icon="ticket-alt" size="lg"></XIcon>
            <span className="ml-2">{tx.TicketCount}</span>
          </>
        }
      </td>
      <td className="text-center">
        <OverlayTrigger
          key="right"
          placement="bottom"
          overlay={
            <Tooltip id="tooltip-right">
              {getTxResultLabel(tx.meta.TransactionResult)}
            </Tooltip>
          }
        >
          <span>
          {tx.meta && tx.meta.TransactionResult && getTxResultStyle(tx.meta.TransactionResult) === 'success' &&
            <XIcon icon="check" className="text-success"/>
          }
          {tx.meta && tx.meta.TransactionResult && getTxResultStyle(tx.meta.TransactionResult) === 'warning' &&
            <XIcon icon="times" className="text-danger"/>
          }
          {tx.meta && tx.meta.TransactionResult && getTxResultStyle(tx.meta.TransactionResult) === 'danger' &&
            <XIcon icon="exclamation" className="text-warning"/>
          }
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
}

TxItem.propTypes = {
  tx: PropTypes.object.isRequired,
  address: PropTypes.string.isRequired,
  activationHash: PropTypes.string,
}

export default TxItem;