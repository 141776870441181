import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="container">
        <Row>
          <Col sm={12} md>
            <h5>Powered by XRP Ledger</h5>
            <p>XRPSCAN is the leading explorer for the XRP Ledger, the home of XRP.</p>
          </Col>
          <Col sm="12" md>
            <h5>Explore</h5>
            <ul className="list-unstyled">
              <li><Link to={`/search`} className="footer-link">Advanced search</Link></li>
              <li><Link to={`/metrics`} className="footer-link">XRPL metrics</Link></li>
              <li><Link to={`/amendments`} className="footer-link">Amendments</Link></li>
              <li><Link to={`/validators`} className="footer-link">Validator registry</Link></li>
              <li><Link to={`/balances`} className="footer-link">XRP Rich List</Link></li>
              <li><Link to={`/amms`} className="footer-link">AMM Pools</Link></li>
            </ul>
          </Col>
          <Col sm="12" md>
            <h5>Support</h5>
            <ul className="list-unstyled">
              <li><a href="mailto:support@xrpscan.com" className="footer-link">support@xrpscan.com</a></li>
              <li><a href="https://docs.xrpscan.com/" target="_blank" rel="noopener noreferrer nofollow" className="footer-link">Documentation</a></li>
              <li><a href="https://docs.xrpscan.com/help/integration-guide" target="_blank" rel="noopener noreferrer nofollow" className="footer-link">Integration guide</a></li>
              <li><a href="https://docs.xrpscan.com/api-documentation/introduction" target="_blank" rel="noopener noreferrer nofollow" className="footer-link">API Docs</a></li>
              <li><a href="https://docs.xrpscan.com/help/contact-us" target="_blank" rel="noopener noreferrer nofollow" className="footer-link">Contact us</a></li>
            </ul>
          </Col>
          <Col sm="12" md className="text-center">
            <h5>Social</h5>
            <ul className="list-inline">
              <li className="list-inline-item"><a href="https://twitter.com/xrpscan" target="_blank" rel="noopener noreferrer nofollow" className="footer-link"><FontAwesomeIcon icon={['fab','twitter']} size="lg"/></a></li>
              <li className="list-inline-item"><a href="https://www.reddit.com/user/xrpscan" target="_blank" rel="noopener noreferrer nofollow" className="footer-link"><FontAwesomeIcon icon={['fab','reddit']}  size="lg"/></a></li>
              <li className="list-inline-item"><a href="mailto:support@xrpscan.com" className="footer-link"><FontAwesomeIcon icon="at" size="lg"/></a></li>
              <li className="list-inline-item"><a href="https://github.com/xrpscan" target="_blank" rel="noopener noreferrer nofollow" className="footer-link"><FontAwesomeIcon icon={['fab','github']} size="lg"/></a></li>
              <li className="list-inline-item"><a href="https://www.instagram.com/xrpscan" target="_blank" rel="noopener noreferrer nofollow" className="footer-link"><FontAwesomeIcon icon={['fab','instagram']} size="lg"/></a></li>
              <li className="list-inline-item"><a href="https://www.threads.net/@xrpscan" target="_blank" rel="noopener noreferrer nofollow" className="footer-link"><FontAwesomeIcon icon={['fad','scribble']} size="lg"/></a></li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <small>&copy; 2017 - 2024 Scrambled Egg Technologies, LLC. All rights reserved.</small>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
