import { library } from '@fortawesome/fontawesome-svg-core';
import { faReddit } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faScribble } from '@fortawesome/pro-duotone-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faSearch as faSearchDuotone } from '@fortawesome/pro-duotone-svg-icons';
import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { faLockOpen } from '@fortawesome/pro-duotone-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRight } from '@fortawesome/pro-duotone-svg-icons';
import { faClipboard } from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { faUser } from '@fortawesome/pro-duotone-svg-icons';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons';
import { faStopwatch } from '@fortawesome/pro-duotone-svg-icons';
import { faTachometer } from '@fortawesome/pro-duotone-svg-icons';
import { faClipboardCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faServer } from '@fortawesome/pro-duotone-svg-icons';
import { faChartBar } from '@fortawesome/pro-duotone-svg-icons';
import { faChartArea } from '@fortawesome/pro-duotone-svg-icons';
import { faCogs } from '@fortawesome/pro-duotone-svg-icons';
import { faVoteYea } from '@fortawesome/pro-duotone-svg-icons';
import { faVoteNay } from '@fortawesome/pro-duotone-svg-icons';
import { faHistory } from '@fortawesome/pro-duotone-svg-icons';
import { faProjectDiagram } from '@fortawesome/pro-duotone-svg-icons';
import { faEmptySet } from '@fortawesome/pro-duotone-svg-icons';
import { faRing } from '@fortawesome/pro-duotone-svg-icons';
import { faCoins } from '@fortawesome/pro-duotone-svg-icons';
import { faExchange } from '@fortawesome/pro-duotone-svg-icons';
import { faTasks } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { faChartPieAlt } from '@fortawesome/pro-duotone-svg-icons';
import { faFireAlt } from '@fortawesome/pro-duotone-svg-icons';
import { faStar } from '@fortawesome/pro-duotone-svg-icons';
import { faFireSmoke } from '@fortawesome/pro-duotone-svg-icons';
import { faWaveformPath } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDoubleUp } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDoubleDown } from '@fortawesome/pro-duotone-svg-icons';
import { faTicketAlt } from '@fortawesome/pro-duotone-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faNetworkWired } from '@fortawesome/pro-duotone-svg-icons';
import { faChartNetwork } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons';
import { faMinus } from '@fortawesome/pro-duotone-svg-icons';
import { faEquals } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDoubleLeft } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDoubleRight } from '@fortawesome/pro-duotone-svg-icons';
import { faHexagonVerticalNft } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsRepeat } from '@fortawesome/pro-duotone-svg-icons';
import { faXmark } from '@fortawesome/pro-duotone-svg-icons';
import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons';
import { faHashtag } from '@fortawesome/pro-duotone-svg-icons';
import { faLayerGroup } from '@fortawesome/pro-duotone-svg-icons';
import { faChartPieSimple } from '@fortawesome/pro-duotone-svg-icons';
import { faMagnifyingGlassPlus } from '@fortawesome/pro-regular-svg-icons';
import { faObjectUnion } from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';

library.add(faReddit);
library.add(faGithub);
library.add(faTwitter);
library.add(faInstagram);
library.add(faAt);
library.add(faSearch);
library.add(faSearchDuotone);
library.add(faLock);
library.add(faLockOpen);
library.add(faLink);
library.add(faBadgeCheck);
library.add(faExclamationCircle);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faClipboard);
library.add(faMoneyCheck);
library.add(faFileInvoice);
library.add(faUser);
library.add(faUsers);
library.add(faStopwatch);
library.add(faTachometer);
library.add(faClipboardCheck);
library.add(faServer);
library.add(faChartBar);
library.add(faChartArea);
library.add(faCogs);
library.add(faVoteYea);
library.add(faVoteNay);
library.add(faHistory);
library.add(faProjectDiagram);
library.add(faEmptySet);
library.add(faRing);
library.add(faCoins);
library.add(faExchange);
library.add(faTasks);
library.add(faCheck);
library.add(faExclamation);
library.add(faTimes);
library.add(faChartPieAlt);
library.add(faFireAlt);
library.add(faStar);
library.add(faFireSmoke);
library.add(faWaveformPath);
library.add(faChevronDoubleUp);
library.add(faChevronDoubleDown);
library.add(faTicketAlt);
library.add(faMinusCircle);
library.add(faNetworkWired);
library.add(faChartNetwork);
library.add(faPlus);
library.add(faMinus);
library.add(faEquals);
library.add(faChevronLeft);
library.add(faChevronDoubleLeft);
library.add(faChevronRight);
library.add(faChevronDoubleRight);
library.add(faHexagonVerticalNft);
library.add(faArrowsRepeat);
library.add(faXmark);
library.add(faUserPlus);
library.add(faHashtag);
library.add(faLayerGroup);
library.add(faChartPieSimple);
library.add(faMagnifyingGlassPlus);
library.add(faScribble);
library.add(faObjectUnion);
library.add(faEllipsis);