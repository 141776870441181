import React, { useEffect, useState } from 'react';
import NexoButton from './NexoButton';
import WolfBetButton from './WolfBetButton';
import NebeusButton from './NebeusButton';
import { XRPSCAN_API_URL } from '../../config';
import { handleResponse } from '../common/Helpers';
import MoonpayButton from './MoonpayButton';

const CAMPAIGN_LOCATION_KEY = "campaign.location";

const SponsorContainer = () => {
  const [location, setLocation] = useState(sessionStorage.getItem(CAMPAIGN_LOCATION_KEY));

  // Fetch location
  useEffect(() => {
    if (typeof location === 'string' && location?.length === 2) {
      // Do nothing
    } else {
  		fetch(`${XRPSCAN_API_URL}/campaign/location`)
	  	.then(handleResponse)
		  .then(data => {
        if (data?.country?.length === 2) {
          setLocation(data.country);
          sessionStorage.setItem(CAMPAIGN_LOCATION_KEY, data.country);
        }
	  	})
		  .catch(error => {})
    }
  }, [location]);

  return (
    <span className="float-right d-flex flex-wrap">
        <span className="mr-2 ml-2 mb-2"><MoonpayButton /></span>
        <span className="mr-2 ml-2 mb-2"><NebeusButton location={location}/></span>
        <span className="mr-2 ml-2 mb-2"><WolfBetButton /></span>
        <span className="ml-2 mb-2"><NexoButton /></span>
    </span>
  );
}

export default SponsorContainer;