import React from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { getTxResultStyle } from '../common/Helpers';
import Money from '../lib/Money';
import TxResult from '../lib/TxResult';
import TxType from '../lib/TxType';
import XIcon from '../lib/XIcon';
import AccountTag from '../lib/AccountTag';

const LedgerTransaction = (props) => {
	const { transactions } = props;

	return (
		<div>
			<Table responsive>
				<thead>
					<tr>
						<th>Index</th>
						<th>Tx hash</th>
						<th>From</th>
						<th></th>
						<th>To</th>
						<th className="text-right">Amount</th>
						<th className="text-right">Fee</th>
						<th className="text-center">Result</th>
					</tr>
				</thead>
				<tbody>
					{ transactions.map((tx) => (
					<tr
						key={tx.meta.TransactionIndex}
						className={ getTxResultStyle(tx.meta.TransactionResult) === 'success' ? '' : getTxResultStyle(tx.meta.TransactionResult, 'table-')}
					>
						<td>{tx.meta.TransactionIndex}</td>
						<td>
							<span className="text-muted address-tag"><Link to={`/tx/${tx.hash}`}>{tx.hash}</Link></span>
						</td>
						<td>
							<span className="text-muted text-nowrap">
								<AccountTag name={tx.AccountName} link minimal>{tx.Account}</AccountTag>
							</span>
						</td>
						<td>
							<TxType type={tx.TransactionType} />
						</td>
						<td>
							<span className="text-muted text-nowrap">
							{ tx.Destination ?
									<AccountTag name={tx.DestinationName} link minimal dt={tx.DestinationTag}>{tx.Destination}</AccountTag>
								: 'XRPL'}
							</span>
						</td>
						<td className="text-right">
							{ tx.Amount &&
								<Money
									value={tx.Amount.value}
									currency={tx.Amount.currency}
									issuer={tx.Amount.issuer}
									drops
									min={6}
									max={6}
								/>
							}
							{ tx.TakerGets &&
								<Money
									value={tx.TakerGets.value}
									currency={tx.TakerGets.currency}
									issuer={tx.TakerGets.issuer}
									drops
									min={6}
									max={6}
								/>
							}
							{ tx.TransactionType === "TicketCreate" && tx.TicketCount > 0 &&
								<>
									<XIcon className="text-info" icon="ticket-alt" size="lg"></XIcon>
									<span className="ml-2">{tx.TicketCount}</span>
								</>
							}
						</td>
						<td className="text-right">
						 <span className="hash text-muted">
								<small>
									<Money value={tx.Fee} currency={'XRP'} min={6} max={6} drops/>
								</small>
							</span>
						</td>
						<td className="text-center">
							<TxResult result={tx.meta.TransactionResult} />
						</td>
					</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
}

LedgerTransaction.propTypes = {
	transactions: PropTypes.array.isRequired,
	error: PropTypes.object,
};

export default LedgerTransaction;