import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AccountTag from '../../lib/AccountTag';
import MoneyPenny from '../../lib/MoneyPenny';

const CommonFields = (props) => {
	const { node } = props;
    if (typeof node === 'object') {
        return (
            <>
                {/* Object ID can be named as index or LedgerIndex: https://xrpl.org/ledger-entry-common-fields.html */}
                {node?.index &&
                <tr>
                    <td>Index:</td>
                    <td>
                        <span className="text-muted hash">{node.index}</span>
                    </td>
                </tr>
                }
                {node?.LedgerIndex &&
                <tr>
                    <td>Index:</td>
                    <td>
                        <span className="text-muted hash">{node.LedgerIndex}</span>
                    </td>
                </tr>
                }

                {node?.Account &&
                <tr>
                    <td>Account:</td>
                    <td>
                        <AccountTag link>{node.Account}</AccountTag>
                    </td>
                </tr>
                }

                {node?.SourceTag >= 0 &&
                <tr>
                    <td>SourceTag:</td>
                    <td>
                        <span>
                            {node.SourceTag}
                        </span>
                    </td>
                </tr>
                }
                
                {node?.Owner &&
                <tr>
                    <td>Owner:</td>
                    <td>
                        <AccountTag link>{node.Owner}</AccountTag>
                    </td>
                </tr>
                }

                {node?.Destination &&
                <tr>
                    <td>Destination:</td>
                    <td>
                        <AccountTag link>{node.Destination}</AccountTag>
                    </td>
                </tr>
                }

                {node?.DestinationTag >= 0 &&
                <tr>
                    <td>DestinationTag:</td>
                    <td>
                        <span>
                            {node.DestinationTag}
                        </span>
                    </td>
                </tr>
                }

                {node?.DestinationNode &&
                <tr>
                    <td>DestinationNode:</td>
                    <td>
                        <span>
                            {node.DestinationNode}
                        </span>
                    </td>
                </tr>
                }

                {node?.AccountTxnID &&
                <tr>
                    <td>AccountTxnID:</td>
                    <td>
                        <span>
                            <Link to={`/tx/${node.AccountTxnID}`}>{node.AccountTxnID}</Link>
                        </span>
                    </td>
                </tr>
                }

                {node?.Sequence >= 0 &&
                <tr>
                    <td>Sequence:</td>
                    <td>
                        <span>
                            {node.Sequence}
                        </span>
                    </td>
                </tr>
                }

                {node?.Expiration >= 0 &&
                <tr>
                    <td>Expiration:</td>
                    <td>
                        <span>
                            {node.Expiration}
                        </span>
                    </td>
                </tr>
                }

                {node?.Amount &&
                <tr>
                    <td>Amount:</td>
                    <td><MoneyPenny amount={node.Amount} showIssuer/></td>
                </tr>
                }
            </>
        );
    } else { 
        return <></>;
    }
}

CommonFields.propTypes = {
	node: PropTypes.object,
}

export default CommonFields;