import React from 'react';
import { Decode } from 'xrpl-tagged-address-codec';
import XBadge from '../lib/XBadge';
import { AdvisoryType, LPTOKEN_PREFIX } from './Constants';
import XIcon from '../lib/XIcon';

/**
* Fetch error helper
* 
* @param {Object} response
*/

export const handleResponse = (response) => {
	return response.json().then(json => {
		return response.ok ? json : Promise.reject(json);
	});
}

/**
 * Is Object empty?
 *
 * @param {Object} Boolean
 */
export const isEmpty = (obj) => {
	if (
		obj &&
		obj.constructor === Object &&
		Object.keys(obj).length === 0
	)
	 {
		return true;
	} else {
		return false;
	}
}

/**
* Return Bootstrap style name that may be applied to a given TxResult code
*
* @param (String) bsStyle
*/

export const getTxResultStyle = (txResult, prefix='') => {
	let style='';
	const code = txResult.substring(0,3);
	switch (code) {
		case 'tec':
		case 'ter':
			style = `${prefix}warning`;
			break;
		case 'tef':
		case 'tel':
		case 'tem':
			style = `${prefix}danger`;
			break;
		case 'tes':
			style = `${prefix}success`;
			break;
		default:
			break;
	}
	return style;
}


/**
* Return human friendly label that may be displayed on page
*
* @param (String) Label
*/

export const getTxResultLabel = (txResult) => {
	return txResult.substring(3).replace(/_/g, ' ');
}


/**
* Return Bootstrap style name that may be applied to a given Tx Type code
*
* @param (String) bsStyle
*/

export const getTxTypeStyle = (txType, prefix='') => {
	let style='';
	switch (txType) {
		case 'CheckCancel':
		case 'EscrowCancel':
		case 'OfferCancel':
			style = `${prefix}secondary`;
			break;
		case 'AccountDelete':
		case 'Clawback':
			style = `${prefix}dark`;
			break;
		case 'Payment':
		case 'NFTokenMint':
		case 'ACTIVATED':
			style = `${prefix}primary`;
			break;
		case 'NFTokenBurn':
			style = `${prefix}danger`;
			break;
		default:
			style = `${prefix}info`;
			break;
	}
	return style;
}


/**
* Return a human friendly name for transaction type
*
* @param (String) Label
*/

export const getTxTypeLabel = (txType) => {
	let type = '';
	switch (txType) {
		case 'AccountSet':
			type = 'Account set';
			break;
		case 'AccountDelete':
			type = 'Account delete';
			break;
		case 'AMMBid':
			type = 'AMM Bid';
			break;
		case 'AMMCreate':
			type = 'AMM Create';
			break;
		case 'AMMDelete':
			type = 'AMM Delete';
			break;
		case 'AMMDeposit':
			type = 'AMM Deposit';
			break;
		case 'AMMVote':
			type = 'AMM Vote';
			break;
		case 'AMMWithdraw':
			type = 'AMM Withdraw';
			break;
		case 'CheckCancel':
			type = 'Check cancel';
			break;
		case 'CheckCash':
			type = 'Check cash';
			break;
		case 'CheckCreate':
			type = 'Check';
			break;
		case 'Clawback':
			type = 'Clawback';
			break;
		case 'DepositPreauth':
			type = 'Deposit preauth';
			break;
		case 'EscrowCancel':
			type = 'Escrow cancel';
			break;
		case 'EscrowCreate':
			type = 'Escrow';
			break;
		case 'EscrowFinish':
			type = 'Escrow finish';
			break;
		case 'NFTokenAcceptOffer':
			type = 'NFT Accept offer';
			break;
		case 'NFTokenBurn':
			type = 'NFT Burn';
			break;
		case 'NFTokenCancelOffer':
			type = 'NFT Cancel offer';
			break;
		case 'NFTokenCreateOffer':
			type = 'NFT Offer';
			break;
		case 'NFTokenMint':
			type = 'NFT Mint';
			break;
		case 'OfferCancel':
			type = 'Offer cancel';
			break;
		case 'OfferCreate':
			type = 'Offer';
			break;
		case 'Payment':
			type = 'Payment';
			break;
		case 'PaymentChannelClaim':
			type = 'Payment channel claim';
			break;
		case 'PaymentChannelCreate':
			type = 'Payment channel';
			break;
		case 'PaymentChannelFund':
			type = 'Payment channel fund';
			break;
		case 'SetRegularKey':
			type = 'Set regular key';
			break;
		case 'SignerListSet':
			type = 'Signer list set';
			break;
		case 'TrustSet':
			type = 'Trust set';
			break;
		case 'EnableAmendment':
			type = 'Enable amendment';
			break;
		case 'TicketCreate':
			type = 'Ticket create';
			break;
		case 'SetFee':
			type = 'Set fee';
			break;
		default:
			type = txType;
			break;
	}
	return type.toUpperCase();
}

/**
* Ledger Entry Types enum: https://xrpl.org/ledger-object-types.html
*/

export const LedgerEntryType = Object.freeze({
	AccountRoot: 'AccountRoot',
	Amendments: 'Amendments',
	AMM: 'AMM',
	Check: 'Check',
	DepositPreauth: 'DepositPreauth',
	DID: 'DID',
	DirectoryNode: 'DirectoryNode',
	Escrow: 'Escrow',
	FeeSettings: 'FeeSettings',
	LedgerHashes: 'LedgerHashes',
	NegativeUNL: 'NegativeUNL',
	NFTokenOffer: 'NFTokenOffer',
	NFTokenPage: 'NFTokenPage',
	Offer: 'Offer',
	PayChannel: 'PayChannel',
	RippleState: 'RippleState',
	SignerList: 'SignerList',
	Ticket: 'Ticket',
})
/**
 * Array of Ledger entry types that claim owner reserves.
 * https://xrpl.org/reserves.html#owner-reserves
 */
export const LedgerEntryTypeOwner = Object.freeze([
	LedgerEntryType.Check,
	LedgerEntryType.DepositPreauth,
	LedgerEntryType.DID,
	LedgerEntryType.Escrow,
	LedgerEntryType.NFTokenOffer,
	LedgerEntryType.NFTokenPage,
	LedgerEntryType.Offer,
	LedgerEntryType.PayChannel,
	LedgerEntryType.SignerList,
	LedgerEntryType.Ticket,
	LedgerEntryType.RippleState,
])

/**
* Return a human friendly name for transaction type
*
* @param (String) Label
*/

export const getLedgerEntryTypeLabel = (lType) => {
	let type = '';
	switch (lType) {
		case LedgerEntryType.AccountRoot:
			type = 'Account Root';
			break;
		case LedgerEntryType.Amendments:
			type = 'Amendments';
			break;
		case LedgerEntryType.AMM:
			type = 'AMM';
			break;
		case LedgerEntryType.Check:
			type = 'Check';
			break;
		case LedgerEntryType.DepositPreauth:
			type = 'Deposit Preauth';
			break;
		case LedgerEntryType.DID:
			type = 'DID';
			break;
		case LedgerEntryType.DirectoryNode:
			type = 'Directory Node';
			break;
		case LedgerEntryType.Escrow:
			type = 'Escrow';
			break;
		case LedgerEntryType.FeeSettings:
			type = 'Fee Settings';
			break;
		case LedgerEntryType.LedgerHashes:
			type = 'Ledger Hashes';
			break;
		case LedgerEntryType.NegativeUNL:
			type = 'Negative UNL';
			break;
		case LedgerEntryType.NFTokenOffer:
			type = 'NFToken Offer';
			break;
		case LedgerEntryType.NFTokenPage:
			type = 'NFToken Page';
			break;
		case LedgerEntryType.Offer:
			type = 'Offer';
			break;
		case LedgerEntryType.PayChannel:
			type = 'Pay Channel';
			break;
		case LedgerEntryType.RippleState:
			type = 'Trustline';
			break;
		case LedgerEntryType.SignerList:
			type = 'Signer List';
			break;
		case LedgerEntryType.Ticket:
			type = 'Ticket';
			break;
		default:
			type = "Object";
			break;
		}
	return type.toUpperCase();
}

/**
* Return Bootstrap style name that may be applied to a given LedgerEntryType
*
* @param (String) bsStyle
*/

export const getLedgerEntryTypeStyle = (lType, prefix='') => {
	let style='';
	switch (lType) {
		case LedgerEntryType.AMM:
		case LedgerEntryType.Check:
		case LedgerEntryType.DID:
		case LedgerEntryType.Escrow:
		case LedgerEntryType.NFTokenPage:
		case LedgerEntryType.Ticket:
		case LedgerEntryType.RippleState:
			style = `${prefix}primary`;
			break;

		case LedgerEntryType.Offer:
		case LedgerEntryType.NFTokenOffer:
			style = `${prefix}info`;
			break;

		case LedgerEntryType.DepositPreauth:
		case LedgerEntryType.SignerList:
		case LedgerEntryType.PayChannel:
			style = `${prefix}secondary`;
			break;

		case LedgerEntryType.AccountRoot:
		case LedgerEntryType.Amendments:
		case LedgerEntryType.DirectoryNode:
		case LedgerEntryType.FeeSettings:
		case LedgerEntryType.LedgerHashes:
		case LedgerEntryType.NegativeUNL:
			style = `${prefix}dark`;
			break;

		default:
			style = `${prefix}info`;
			break;
	}
	return style;
}

// Amendment styles

/**
* Return Bootstrap style name that may be applied EnableAmendment tx statuses
*
* @param (Number) bsStyle
*/

export const getEnableAmendmentStyle = (flag, prefix='') => {
	if (flag === 65536)
		return `${prefix}info`;
	else if (flag === 131072)
		return `${prefix}warning`;
	else
		return `${prefix}success`;
}

/*
* Get Amendment Enable/Got-Majority/Lost-Majority status by looking at tx flags.
*/
export const getAmendmentStatus = (flag) => {
	if (flag === 65536) {
		return "Acquired Majority";
	}
	else if (flag === 131072) {
		return "Lost Majority";
	}
	else if (!flag || flag === 0) {
		return "Enabled";
	}
	else {
		return "Unavailable";
	}
}



// Validator styles

/**
* Return Bootstrap style name that may be applied validator's agreements
*
* @param (String) bsStyle
*/

export const getAgreementStyle = (score, prefix='', success = 'success') => {
	let s = score * 100;
	if (s === 100)
		return `${prefix}${success}`;
	else if (s < 100 && s >= 50)
		return `${prefix}warning`;
	else if (s < 50)
		return `${prefix}danger`;
}

/**
* Return Bootstrap style name that may be applied validator's chain
*
* @param (String) bsStyle
*/

export const getChainStyle = (chain, prefix='') => {
	return (chain === 'main') ? `${prefix}success` : `${prefix}secondary`;
}

/**
* Return human friendly label for Affected Node Type.
*
* @param (String) Label
*/

export const getAffectedTypeLabel = (affectType) => {
	let type='';
	switch (affectType) {
		case 'ModifiedNode':
			type = 'Modified node';
			break;
		case 'CreatedNode':
			type = 'Created node';
			break;
		case 'DeletedNode':
			type = 'Deleted node';
			break;
		default:
			type = 'Updated Node';
			break;
	}
	return type;
}

/**
* Return human friendly label for Affected Node Type.
*
* @param (String) Label
*/

export const getAffectedTypeStyle = (affectType, prefix='') => {
	let style='';
	switch (affectType) {
		case 'ModifiedNode':
			style = `${prefix}secondary`;
			break;
		case 'CreatedNode':
			style = `${prefix}success`;
			break;
		case 'DeletedNode':
			style = `${prefix}warning`;
			break;
		default:
			style = '';
			break;
	}
	return style;
}

/**
* Return Bootstrap table row style for Amendment vote status
*
* @param (String) Style
*/

export const getAmendmentVoteStyle = (count, threshold) => {
	if (count > threshold) {
		return 'table-success';
	}
	else {
		return 'table-warning';
	}
}

/**
* Return Bootstrap Card style for Advisory status
*
* @param (String) Style
*/

export const getAdvisoryTypeStyle = (advisory) => {
	let style='';
	if (advisory && advisory.type) {
		switch (advisory.type) {
			case AdvisoryType.SPAM:
				style = `warning`;
				break;
			case AdvisoryType.HACK:
				style = `warning`;
				break;
			case AdvisoryType.LEA:
				style = `danger`;
				break;
			default:
				style = '';
				break;
		}
	}
	return style;
}


/**
* Return account activation status: [new|active|deleted]
*
* @param (String) [new|active|deleted]
*/

export const getAccountActivationStatus = (account) => {
	let accountStatus = 'active';
	if ( account && account.error && account.error.error === 'actNotFound' ) {
		if ( account.parent && account.inception ) {
			accountStatus = 'deleted';
		}
		else {
			accountStatus = 'new';
		}
	}
	return accountStatus;
}


/**
* Return Bootstrap Card style for Deleted Accounts
*
* @param (String) Style
*/

export const getInactiveAccountStyle = (inactiveAccountType) => {
	if (inactiveAccountType === 'deleted') {
		return 'dark';
	}
	else if (inactiveAccountType === 'new') {
		return 'primary';
	}
}

/**
 * List of known Blackhole Accounts (0, 1, NAME, NaN)
 */
export const BLACKHOLE_ACCOUNTS = [
	'rrrrrrrrrrrrrrrrrrrrrhoLvTp',
	'rrrrrrrrrrrrrrrrrrrrBZbvji',
	'rrrrrrrrrrrrrrrrrNAMEtxvNvQ',
	'rrrrrrrrrrrrrrrrrrrn5RM1rHd',
]

/**
* Return Account blackhole status
*
* @param (Object) [true|false]
*/

export const getBlackholeAccountStatus = (settings) => {
	if (
		settings &&
		settings.disableMasterKey === true &&
		BLACKHOLE_ACCOUNTS.includes(settings.regularKey) &&
		!settings.signers
	) {
		return true;
	}
	else {
		return false;
	}
}

/**
* Return Bootstrap Card style for Blackholed Accounts
*
* @param () Style
*/

export const getBlackholeAccountStyle = (blackholeStatus) => {
	return blackholeStatus ? 'dark' : '';
}

/**
* Return tags for XRP Ledger state
*
* @param (String) State
*/

export const XRPLFlagInterval = 256;

export const getXRPLState = (counter) => {
	let state = <XBadge variant="primary">PROPOSING</XBadge>;
	switch (counter) {
		case 1:
			state = <XBadge variant="warning">VOTING</XBadge>;
			break;
		case 0:
			state = <XBadge variant="info">FLAG LEDGER</XBadge>;
			break;
		case 255:
			state = <XBadge variant="danger">INSERT TX</XBadge>;
			break;
		case 254:
			state = <XBadge variant="success">NEW SETTINGS</XBadge>;
			break;
		default:
			break;
	}
	return state;
}


/** Convert XRPL Epoch to Unix Epoch time
*
* @param (Integer) Unix epich time
*
* Reference: https://developers.ripple.com/basic-data-types.html#specifying-time
*/
export const RIPPLE_EPOCH = 946684800;
export const rippleTime2UnixTime = (rippleTime) => {
	return rippleTime + RIPPLE_EPOCH;
}


/** Decode address from X-Address format to r-Address format.
*
* @param (String) X-Address
*
* Reference: https://github.com/xrp-community/standards-drafts/issues/6
*/

export const decodeAddress = (address) => {
	if (
		address.startsWith('X') &&
		address.length >= 46 &&
		address.length <= 48) {
		try {
			return Decode(address).account;
		}
		catch(error) {
			return address;
		}
	}
	else {
		return address;
	}
}

/** Convert 40 character HEX currency codes to ASCII codes
*
* @param (String) ASCII
*
* Reference: https://xrpl.org/currency-formats.html#currency-codes
*/

export const hex2ascii = (hex) => {
	const hexCode = hex.toString();
	let asciiCode = '';
	for (let i = 0; (i < hexCode.length && hexCode.substr(i, 2) !== '00'); i += 2) {
		asciiCode += String.fromCharCode( parseInt(hexCode.substr(i, 2), 16));
	}
	return asciiCode;
}

/**
 * Return correct human readable currency code, given ISO 4217 or HEX codes
 *
 * @param (String) ASCII
 */

export const currencyName = (code) => {
	if (code && code.length === 40) {
		if (code.startsWith('03')) {
			return <>{LPTOKEN_PREFIX}&nbsp;{code.substring(0,4)}<XIcon icon="ellipsis" faStyle={'fas'} className={'ml-0 mr-0'}/>{code.substring(code.length - 4)}</>;
		} else {
			return hex2ascii(code);
		}
	} else {
		return (typeof code === 'string') ? code.substring(0,16) : code;
	}
}

/*
* Was the token redeemed/burned? Condition: (Destination === Token Issuer)
*/
export const isTokenBurnTx = (tx) => {
	return (
		tx &&
		tx.meta &&
		tx.meta.delivered_amount &&
		tx.meta.delivered_amount.issuer &&
		tx.meta.delivered_amount.issuer === tx.Destination &&
		tx.TransactionType === 'Payment'
	)
	?	true : false;
}

/*
* Return a random element from an Array.
*/
export const getRandomElement = (items) => {
	let index = 0;
	try {
		index = Math.floor((Math.random() * 10)) % items.length;
		return items[index];
	} catch (error) {
	}
}

/*
* Convert transfer fee specified in 1/1000 to percent.
*/
export const transferFeeToPercent = (transferFee) => {
	if (Number(transferFee) >= 0) {
		return `${Number(transferFee) / 1000}`;
	}
}

/*
* Test if the parameter `n` is numeric or not
*/
export const isNumeric = (n) => {
	// we only process strings!
	if (typeof n != "string") return false
	// use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)
	// and ensure strings of whitespace fail
	return !isNaN(n) && !isNaN(parseFloat(n))
}

/*
* Converts from TradingFee units to percent.
*/
export const feeUnitToPercent = (trading_fee) => {
	if (Number(trading_fee)) {
		return Number(trading_fee) / 1000;
	} else {
		return trading_fee;
	}
}