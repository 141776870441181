import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';

const excludedCountries = [
  "US",
  "RU",
  "AF",
  "CU",
  "ER",
  "IR",
  "IQ",
  "KP",
  "LY",
  "MM",
  "SD",
  "SO",
  "SY",
  "YE",
];

const NebeusButton = (props) => {
  const { location } = props;
  if (!location || excludedCountries.includes(location)) {
    return <></>
  }

  const popover = (
    <Popover id="popover-sponsor">
      <Popover.Title>
        Nebeus Mastercard Linked to Crypto - Spend with crypto instantly. Worldwide.&nbsp;
        <span className="small"><Badge variant="secondary">SPONSORED</Badge></span>
      </Popover.Title>
      <Popover.Content>
      Discover all the perks:
      <ul>
        <li>Globally spend at 90M+ stores with crypto</li>
        <li>Easily swap cryptos for flexible spending</li>
        <li>Opt for convenient card currency: EUR, GBP, or USD</li>
        <li>Free ATM Withdrawals: $200 monthly</li>
      </ul>
      </Popover.Content>
    </Popover>
  );

  return (
    <span>
        <OverlayTrigger
          key={'bottom'}
          placement={'bottom'}
          overlay={popover}
        >
          <Button
            variant="info"
            size="sm"
            href="https://api.xrpscan.com/api/v1/campaign/nebeusbutton"
            target="_blank"
            rel="noopener nofollow"
            className="dropdown-toggle"
          >Get a Free Crypto Card</Button>
        </OverlayTrigger>
    </span>
  )
}

NebeusButton.propTypes = {
	location: PropTypes.string.isRequired,
}

export default NebeusButton;